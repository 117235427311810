<template>
  <div class="about">
    <h1>About the Project</h1>
    <div class="button-links">
      <router-link to="/" class="btn">Back to the Data</router-link>
    </div>
    <div class="description section-display">
      <div class="subsection">
        <h2>How It All Began</h2>
        <p>In January, 2021, my child convinced me that I needed to get Animal Crossing for my Switch. Initially, I wasn't so sure, but it turned out that I enjoyed the game.</p>
        <p>Through my gameplay, I found that there were certain crafting materials that I was constantly short of; namely Wood, Clay, and Iron Nuggets. This made me curious about the distribution of materials that I was getting from hitting rocks and chopping trees. Thus was born the idea for the Slumbernot Resource Project.</p>
      </div>
      <div class="subsection">
        <h2>Methodology</h2>
        <p>Each morning for the month of December, 2021, I logged the output of the 6 rocks on my island and 50 trees. The output of the rocks was tracked to each rock, and the wood output was tracked as total output from the trees. Included in the 50 trees were 12 palm trees, 8 hardwood trees, and 30 fruit trees. The trees were chosen to make the process as quick as possible, so they are all on the first level of the island and no cedar trees were included.</p>
      </div>
      <div class="subsection">
        <h2>Technologies Used</h2>
        <p>At about the same time I had the idea to do this, I decided I wanted to familiarize myself with <a href="https://v3.vuejs.org">Vue.js 3</a>. My day job is a web developer and I generally stick to back-end development. When I do have to do front-end work, I often use Vue.js. I had read some good things about Vue 3 and the new Composition API and wanted to familiarize myself with it. The data from Slumbernot seemed like a perfect fit and gave me some real-world data to build an application.</p>

        <p>For the front-end, I used Vue 3 with the vue-chart-3 plugin and chart.js for the charts, axios for getting data from my API, and the vue3-date-time-picker for date range selection.</p>

        <p>For the back-end, I created a small REST API using the Lumen framework.</p>
      </div>
      <div class="subsection">
        <h2>Parting Notes</h2>
        <p>I'm curious about the wood output from different types of trees. I may have to do this again but log the data for each tree type to see if there's a difference in output from the different tree types. But that sounds a lot like work ...</p>
        <p>If you're here looking at the data, Thank you!</p>
        <p>If you're in need of a developer, look me up: <a href="https://keithhatfield.com">Keith Hatfield Development</a></p>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.about {
  padding: 10px 5%;

  h1 {
    font-weight: normal;
    font-size: 1.8em;
  }

  h2 {
    font-weight: normal;
  }
}
</style>
